import React from "react";
import Services from "./services";

import "./styles.scss";

function View() {
  return <Services></Services>;
}

export default View;
