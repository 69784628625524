import React from "react";
import View from "./view";

function Index() {
  return (
    <div>
      <View></View>
    </div>
  );
}

export default Index;
